








































































































































































































































































































import { Component, Ref, Vue } from "vue-property-decorator";

import NewApplicationBasicStep from "@/application-manager/components/NewApplicationBasicStep.vue";
import { Dictionary, AppSection } from "@/shared/models";
import JurisdictionResponseModel from "@/application-manager/models/JurisdictionResponseModel";
import ExecutionRequestModel from "@/application-manager/models/ExecutionRequestModel";
import BatchResponseModel from "@/application-manager/models/BatchResponseModel";
import BatchExecutionChainResponseModel, {
  ExecutionChain,
} from "@/application-manager/models/BatchExecutionChainResponseModel";
import ScenarioResponseModel from "@/application-manager/models/ScenarioResponseModel";
import { ExecutionParameterModel } from "@/application-manager/models/ExecutionParameterResponseModel";
import ValidUtil from "@/shared/utils/ValidUtil";
import { VueForm, VueAutocomplete } from "@/shared/types/ExtendedVueType";

@Component({
  components: {
    NewApplicationBasicStep,
  },
})
export default class NewApplicationView extends Vue {
  @Ref("form") readonly form!: Array<VueForm>;
  @Ref("parameterInput") readonly parameterInput!: Array<VueAutocomplete>;

  localValue: ExecutionRequestModel = new ExecutionRequestModel();
  executions: any = [];
  isValid = false;
  isExecutionParametersValid = false;
  isStarted = false;
  isAllScriptsDone = false;
  isStartedExecutionScripts = false;
  step = 1;
  bodyParams: Record<string, string> = {};
  expanded = [];

  readonly AppSection = AppSection;
  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly headers = [
    {
      text: this.$lang("applicationManager.execution.command"),
      align: "start",
      value: "command",
      sortable: false,
    },
    {
      text: this.$lang("applicationManager.execution.status"),
      align: "start",
      value: "success",
      sortable: false,
    },
    // {
    //   text: this.$lang("jsonEditor.actions"),
    //   value: "actions",
    //   sortable: false,
    //   width: "100px",
    // },
  ];

  readonly expandedHeaders = [
    {
      text: "",
      value: "data-table-expand",
      width: "60px",
    },
    {
      text: this.$lang("applicationManager.configuration.key"),
      align: "start",
      value: "key",
      sortable: false,
      width: "50%",
    },
    {
      text: this.$lang("applicationManager.configuration.value"),
      align: "start",
      value: "value",
      sortable: false,
    },
  ];

  get jurisdictions(): Array<JurisdictionResponseModel> {
    return this.$store.state.jurisdictionStore.jurisdictions;
  }

  get platforms(): Array<Dictionary> {
    return this.$store.state.application.platforms;
  }

  get isSavingInProgress(): boolean {
    return this.$store.state.executionStore.isSavingInProgress;
  }

  get errorMessageForExecutionStep(): string {
    return this.$store.state.executionStore.errorMessageForExecutionStep;
  }

  get selectedScenariosInBatch(): Array<ScenarioResponseModel> {
    return (
      this.$store.state.batchStore.batches
        .find(
          (batch: BatchResponseModel) => batch.id === this.localValue?.batchId
        )
        ?.scenarios.map((scenario: ScenarioResponseModel) => ({
          ...scenario,
          valid: true,
        })) ?? []
    );
  }

  get batchExecutionChain(): BatchExecutionChainResponseModel {
    return this.$store.state.executionStore.batchExecutionChain;
  }

  get hasErrorInExecution(): boolean {
    return this.executions.some((execution: any) => !execution.success);
  }

  get batches(): Array<BatchResponseModel> {
    return this.$store.state.batchStore.batches;
  }

  get executionParameters(): Array<ExecutionParameterModel> {
    return this.$store.state.executionStore.executionParameters;
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("applicationManager.application.creatingApplicationTitle")
    );
  }

  async mounted() {
    await Promise.all([
      this.$store.dispatch("loadJurisdictions"),
      this.$store.dispatch("loadBatches"),
    ]);

    if (!this.$route.query.continue) {
      this.localValue.batchId = this.batches.find(
        (batch) => !!batch.isDefault
      )?.id;
    }

    if (this.$route.query.continue) {
      await this.$store.dispatch(
        "loadBatchExecution",
        this.$route.query.batchExecutionId
      );

      this.localValue = new ExecutionRequestModel(
        this.batchExecutionChain.executionChains[0].profileId,
        this.batchExecutionChain.executionChains[0].application,
        this.batchExecutionChain.executionChains[0].platform,
        this.batchExecutionChain.batchId
      );

      this.step = Number(this.batchExecutionChain.scenariosComplete) + 1;
      this.handleNextStep();
    }
  }

  async handleNextStep() {
    let executionChain: ExecutionChain | null = null;
    this.step++;
    this.isStartedExecutionScripts = false;

    if (this.isAllScriptsDone) {
      this.executions = [];
    }

    executionChain = this.batchExecutionChain.executionChains[this.step - 2];

    await this.$store.dispatch(
      "checkExecutionParameters",
      executionChain.executionId
    );

    this.bodyParams = this.executionParameters.reduce(
      (res: Record<string, string>, currItem: ExecutionParameterModel) => {
        return {
          ...res,
          [currItem.name]: String(currItem.defaultValue),
        };
      },
      {}
    );

    this.parameterInput[this.step - 2].focus();

    if (this.form?.length) {
      (this.form as any)[this.step - 2].resetValidation();
    }

    if (!this.executionParameters.length) {
      this.handleStartScripts();
    }
  }

  async handleStartScripts() {
    const executionChain =
      this.batchExecutionChain.executionChains[this.step - 2];

    this.isStartedExecutionScripts = true;

    for (let i = 0; i < executionChain.executionChainElements.length; i++) {
      const temp = {
        id: i + 1,
        command: executionChain.executionChainElements[i].command,
        success: true,
        isLoading: true,
      };
      this.executions.push(temp);

      await this.$store
        .dispatch("createExecutionByExecutionId", {
          payload: {
            PROJECT_ID: this.localValue.application,
            PLATFORM_ID: this.localValue.platform,
            ...this.bodyParams,
          },
          profileId: this.localValue.profileId,
          executionId: executionChain.executionId,
          step: i,
        })
        .then((result) => {
          const found = this.executions.find(
            (item: any) => item.id === temp.id
          );
          found.isLoading = false;
          found.commandContext = Object.entries(result.commandContext).map(
            ([key, value]) => ({
              key,
              value,
            })
          );
        })
        .catch(() => {
          const foundScenario: any =
            this.selectedScenariosInBatch[this.step - 2];
          const foundExecution = this.executions.find(
            (item: any) => item.id === temp.id
          );
          foundExecution.success = false;
          foundExecution.isLoading = false;
          foundExecution.errorText = this.errorMessageForExecutionStep;
          foundScenario.valid = false;
        });
    }

    this.isAllScriptsDone = true;
  }

  async handleStart() {
    this.isStarted = true;

    await this.$store.dispatch("createExecutionByBatchId", {
      payload: this.localValue.getPreparedData(),
      batchId: this.localValue.batchId,
    });

    this.handleNextStep();
  }

  canExpand(item: any): boolean {
    return !!item?.commandContext?.length;
  }

  isShowParamentField(item: ExecutionParameterModel): boolean {
    return !!(
      !item?.dependentOn ||
      (item?.dependentOn &&
        this.bodyParams[item?.dependentOn as string] === item?.dependentValue)
    );
  }
}
