











































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { debounce } from "lodash";

import ExecutionRequestModel from "@/application-manager/models/ExecutionRequestModel";
import JurisdictionResponseModel from "@/application-manager/models/JurisdictionResponseModel";
import BatchResponseModel from "@/application-manager/models/BatchResponseModel";
import { Dictionary } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class NewApplicationBasicStep extends Vue {
  @Prop() value!: ExecutionRequestModel;
  @Prop({ default: false }) isStarted!: boolean;
  @Prop({ default: false }) isAllScriptsDone!: boolean;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];

  get localValue() {
    return this.value;
  }

  set localValue(value: ExecutionRequestModel) {
    this.$emit("input", value);
  }

  get jurisdictions(): Array<JurisdictionResponseModel> {
    return this.$store.state.jurisdictionStore.jurisdictions;
  }

  get platforms(): Array<Dictionary> {
    return this.$store.state.application.platforms;
  }

  get batches(): Array<BatchResponseModel> {
    return this.$store.state.batchStore.batches;
  }

  get isApplicationNameValid(): boolean {
    return this.$store.state.executionStore.applicationNameValidation.valid;
  }

  get applicationNameValidationMessage(): string {
    return this.$store.state.executionStore.applicationNameValidation.message;
  }

  get nameErrorMessages(): Array<string> {
    return !this.isApplicationNameValid
      ? [this.applicationNameValidationMessage]
      : [];
  }

  @Watch("localValue.platform")
  @Watch("localValue.application")
  watchName = debounce(() => {
    if (!this.$route.query.continue && this.localValue.application) {
      this.$store.dispatch("checkApplicationNameValidation", {
        application: this.localValue.application,
        platform: this.localValue.platform,
      });
    }
  }, 500);
}
