export default class ExecutionRequestModel {
  constructor(
    public profileId = 0,
    public application = "",
    public platform = "",
    public batchId?: number
  ) {}

  getPreparedData() {
    return {
      profileId: this.profileId,
      application: this.application,
      platform: this.platform,
    };
  }
}
